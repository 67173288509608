import React, { useCallback, useEffect, useState } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { getUserData } from "utils/auth";
import { ROLE_USER } from "utils/roles";

const GET_RENTER_REPORT_TYPES = gql`
  query shareableRenterReportTypes($id: ID!) {
    shareableRenterReportTypes(id: $id) {
      reportTypes
    }
  }
`;

const GET_RENTER_TU_REPORTS = gql`
  query shareableRenterReports($id: ID!, $types: [String!]) {
    shareableRenterReports(id: $id, types: $types) {
      reports {
        html
        type
      }
    }
  }
`;

const GET_LANDLORD_REPORT_TYPES = gql`
query shareableLandlordReportTypes($id: ID!) {
  shareableLandlordReportTypes(id: $id) {
    reportTypes
  }
}
`;

const GET_LANDLORD_TU_REPORTS = gql`
  query shareableLandlordReports($id: ID!, $types: [String!]) {
    shareableLandlordReports(id: $id, types: $types) {
      reports {
        html
        type
      }
    }
  }
`;

// will poll the API until the reports are ready,
// and then will fetch the reports
const useShareableReports = (applicantDataId) => { 

  const [activeTab, setActiveTab] = useState(0);
  const user = getUserData();
  const landlordOrUser = user.role === ROLE_USER ? 'user' : 'landlord';
  const GET_REPORT_TYPES = landlordOrUser === 'user' ? GET_RENTER_REPORT_TYPES : GET_LANDLORD_REPORT_TYPES;
  const GET_TU_REPORTS = landlordOrUser === 'user' ? GET_RENTER_TU_REPORTS : GET_LANDLORD_TU_REPORTS;

  const { startPolling, stopPolling, data: reportTypesResponse } = useQuery(GET_REPORT_TYPES, {
    variables: { id: applicantDataId },
  });

  const reportTypes = landlordOrUser === 'landlord' ? reportTypesResponse?.shareableLandlordReportTypes?.reportTypes : reportTypesResponse?.shareableRenterReportTypes?.reportTypes;
  const [fetchReports, { loading: loadingReports, data }] = useLazyQuery(GET_TU_REPORTS, {
    variables: { id: applicantDataId, types: reportTypes },
  });

  const reports = landlordOrUser === 'landlord' ? data?.shareableLandlordReports?.reports : data?.shareableRenterReports?.reports;

  useEffect(() => {
    const reportTypes = landlordOrUser === 'landlord' ? reportTypesResponse?.shareableLandlordReportTypes?.reportTypes : reportTypesResponse?.shareableRenterReportTypes?.reportTypes;
    if (reportTypes && reportTypes.length > 0) {
      stopPolling();
      fetchReports();
    }
  }, [reportTypesResponse, stopPolling, fetchReports, user.role, landlordOrUser]);

  const startPollingExport = useCallback(() => startPolling(3000), [startPolling]);

  return {
    startPolling: startPollingExport,
    loading: loadingReports,
    reports,
    activeTab,
    setActiveTab,
  };
};

export default useShareableReports;
