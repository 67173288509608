import {
  ApprovedIcon,
  CanceledIcon,
  DeniedIcon,
  InProgressIcon,
  NeedsReviewIcon,
  NeedsUpdatesIcon,
  NewIcon,
  OpenIcon,
} from 'components/Icons/ApplicationStatusIcons';
import { buildValueMap } from 'utils/functions';
import createDecorator from 'final-form-focus';

export const focusOnErrors = createDecorator();

export const MATCH_STATUS = {
  MATCH: 'MATCH',
  NOT_REGISTERED: 'NOT_REGISTERED',
  NOT_LINKED: 'NOT_LINKED',
  NONE: false,
};

export const MATCH_STATUS_LABELS = {
  MATCH: 'Matched',
  NOT_LINKED: 'Not Linked',
  NOT_REGISTERED: 'Not Registered',
  NONE: 'None',
};

export const APP_TYPE_GROUP = 'group';
export const APP_TYPE_SINGLE = 'single';

export const APPLICANT_DATA_STATUSES = {
  IN_PROGRESS: 'in_progress',
  NEEDS_UPDATES: 'needs_updates',
  SUBMITTED: 'submitted',
};

export const APP_STATUS_IN_PROGRESS = 'in_progress';
export const APP_STATUS_NEEDS_REVIEW = 'needs_review';
export const APP_STATUS_SUBMITTED = 'submitted';
export const APP_STATUS_OPEN = 'open';
export const APP_STATUS_NEEDS_UPDATES = 'needs_updates';
export const APP_STATUS_DENIED = 'denied';
export const APP_STATUS_APPROVED = 'approved';
export const APP_STATUS_CANCELED = 'canceled';
export const APP_STATUS_DENIED_OTHER = 'other';
export const APP_STATUS_CONDITIONALLY_APPROVED = 'conditionally_approved';
export const APP_STATUS_DENIED_CRIMINAL = 'criminal';

export const APPLICATION_STATUS = [
  { value: APP_STATUS_IN_PROGRESS, label: 'In Progress', icon: InProgressIcon },
  { value: APP_STATUS_SUBMITTED, label: 'New', icon: NewIcon },
  { value: APP_STATUS_OPEN, label: 'Open', icon: OpenIcon },
  { value: APP_STATUS_NEEDS_UPDATES, label: 'Needs Updates', icon: NeedsUpdatesIcon },
  { value: APP_STATUS_NEEDS_REVIEW, label: 'Needs Review', icon: NeedsReviewIcon },
  { value: APP_STATUS_DENIED, label: 'Denied', icon: DeniedIcon },
  { value: APP_STATUS_APPROVED, label: 'Approved', icon: ApprovedIcon },
  { value: APP_STATUS_CANCELED, label: 'Canceled', icon: CanceledIcon },
  { value: APP_STATUS_CONDITIONALLY_APPROVED, label: 'Conditionally Approved', icon: ApprovedIcon },
];

export const APPLICATION_STATUS_FILTERS = [
  { value: APP_STATUS_IN_PROGRESS, label: 'In Progress', icon: InProgressIcon },
  { value: APP_STATUS_SUBMITTED, label: 'New', icon: NewIcon },
  { value: APP_STATUS_OPEN, label: 'Open', icon: OpenIcon },
  { value: APP_STATUS_NEEDS_UPDATES, label: 'Needs Updates', icon: NeedsUpdatesIcon },
  { value: APP_STATUS_NEEDS_REVIEW, label: 'Needs Review', icon: NeedsReviewIcon },
  { value: APP_STATUS_DENIED, label: 'Denied', icon: DeniedIcon },
  { value: APP_STATUS_APPROVED, label: 'Approved', icon: ApprovedIcon },
  { value: APP_STATUS_CONDITIONALLY_APPROVED, label: 'Conditionally Approved', icon: ApprovedIcon },
];

export const APPLICANT_DATA_STATUS = [
  { value: APPLICANT_DATA_STATUSES.IN_PROGRESS, label: 'In Progress', icon: InProgressIcon },
  { value: APPLICANT_DATA_STATUSES.SUBMITTED, label: 'Submitted', icon: NewIcon },
  { value: APPLICANT_DATA_STATUSES.NEEDS_UPDATES, label: 'Needs Updates', icon: NeedsUpdatesIcon },
];

export const APPLICATION_STATUS_ACTIONS = [
  { value: APP_STATUS_OPEN, label: 'Mark as Open/Active' },
  { value: APP_STATUS_NEEDS_UPDATES, label: 'Updates Needed' },
  { value: APP_STATUS_APPROVED, label: 'Approved' },
  { value: APP_STATUS_DENIED, label: 'Denied' },
  { value: APP_STATUS_CANCELED, label: 'Cancel' },
];

export const DC_APPLICATION_STATUS_ACTIONS = [
  { value: APP_STATUS_OPEN, label: 'Mark as Open/Active' },
  { value: APP_STATUS_NEEDS_UPDATES, label: 'Updates Needed' },
  { value: APP_STATUS_CONDITIONALLY_APPROVED, label: 'Conditionally Approved' },
  { value: APP_STATUS_DENIED, label: 'Denied' },
  { value: APP_STATUS_CANCELED, label: 'Cancel' },
];

export const APP_DATA_STATUS_IN_PROGRESS_LINK_TEXT = 'Continue Application';
export const APP_DATA_STATUS_NEEDS_UPDATES_LINK_TEXT = 'Update My Application';
export const APP_DATA_STATUS_SUBMITTED_LINK_TEXT = 'Review my Application';

export const APPLICATION_STEP_LABELS = {
  information: 'Information',
  'application-type': 'Application Type',
  'co-applicants': 'Co-Applicants',
  'financial-information': 'Employment Information',
  'renter-history': 'Rental History',
  'additional-info': 'Additional Details',
  'background-check-form': 'Background Check',
  summary: 'Review',
  success: 'Confirmation',
};

export const PROPERTY_STATUS_ACTIVE = 'active';
export const PROPERTY_STATUS_INACTIVE = 'inactive';

export const PROPERTY_STATUS_OPTIONS = [
  { value: PROPERTY_STATUS_ACTIVE, label: 'Active' },
  { value: PROPERTY_STATUS_INACTIVE, label: 'Inactive' },
];

export const PROPERTY_STATUS_OPTIONS_MAP = buildValueMap(PROPERTY_STATUS_OPTIONS);

export const LEASE_TERMS_MAP = {
  underThreeMonths: 'Under 3 months',
  ThreePlus: '3+ months',
  SixPlus: '6+ months',
  TwelvePlus: '12+ months',
  TwentyFourPlus: '24+ months',
  Flexible: 'Flexible',
};

export const LEASE_TERMS = [
  { value: 'underThreeMonths', label: 'Under 3 months' },
  { value: 'ThreePlus', label: '3+ months' },
  { value: 'SixPlus', label: '6+ months' },
  { value: 'TwelvePlus', label: '12+ months' },
  { value: 'TwentyFourPlus', label: '24+ months' },
  { value: 'Flexible', label: 'Flexible' },
];

export const ALLOWED_PETS = [
  { value: 'dogs', label: 'Dogs', singular: 'Dog' },
  { value: 'cats', label: 'Cats', singular: 'Cat' },
  { value: 'birds', label: 'Birds', singular: 'Bird' },
  { value: 'fish', label: 'Fish', singular: 'Fish' },
  { value: 'reptiles', label: 'Reptiles', singular: 'Reptile' },
];

export const ALLOWED_PETS_MAP = buildValueMap(ALLOWED_PETS);

export const EMPLOYMENT_LENGTHS = [
  { value: '', label: '- Select One -', isDefault: true },
  { value: 'less-than-6-months', label: 'Less than 6 months' },
  { value: '6-months-to-1-year', label: '6 months to 1 year' },
  { value: '2years', label: '2 years' },
  { value: '3years', label: '3 years' },
  { value: '4years', label: '4 years' },
  { value: '5years-plus', label: '5 years +' },
];

export const ACCEPTED_INCOME_PROOF_MAP = {
  ytd_w2: 'YTD W2',
  pref_cal_year_w2: 'Previous Calendar Years W2',
  prev_cal_year_tax_return: 'Previous Calendar Years Tax Return',
  recent_pay_stub: 'Most Recent Pay Stub',
  letter_from_employer: 'Letter from Employer',
  housing_voucher: 'Housing Voucher'
};

export const ACCEPTED_INCOME_PROOF = [
  { value: 'ytd_w2', label: 'YTD W2' },
  { value: 'pref_cal_year_w2', label: 'Previous Calendar Years W2' },
  { value: 'prev_cal_year_tax_return', label: 'Previous Calendar Years Tax Return' },
  { value: 'recent_pay_stub', label: 'Most Recent Pay Stub' },
  { value: 'letter_from_employer', label: 'Letter from Employer' },
  { value: 'housing_voucher', label: 'Housing Voucher'}
];

export const NOMADIC_ACCEPTED_INCOME_PROOF = [
  { value: 'pref_cal_year_w2', label: 'Previous Calendar Years W2' },
  { value: 'prev_cal_year_tax_return', label: 'Previous Calendar Years Tax Return' },
  { value: 'recent_pay_stub', label: 'Most Recent Pay Stub' },
  { value: 'letter_from_employer', label: 'Letter from Employer' },
  { value: 'housing_voucher', label: 'Housing Voucher'}
];

export const ACCEPTED_INCOME_PROOF_VALUE_MAP = buildValueMap(ACCEPTED_INCOME_PROOF);

export const REFERENCE_LETTER_TYPES = [
  { value: 'employer', label: 'Employer' },
  { value: 'landlord', label: 'Landlord' },
  { value: 'peer', label: 'Peer' },
];

export const ALERT_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
};

export const UNEMPLOYED = 'unemployed';
export const SELF_EMPLOYED = 'self-employed';
export const EMPLOYED = 'employed';
export const RETIRED = 'retired';
export const EMPLOYMENT_STATUS_OPTIONS = [
  { value: '', label: '- Please Select One -', isDefault: true },
  { value: EMPLOYED, label: 'Employed' },
  { value: SELF_EMPLOYED, label: 'Self-Employed' },
  { value: UNEMPLOYED, label: 'Unemployed' },
  { value: RETIRED, label: 'Retired' },
];

export const LENGTH_OF_RESIDENCY_OPTIONS = [
  { value: '', label: '- Select One -', isDefault: true },
  { value: 'less_than_6_months', label: 'Less than 6 months' },
  { value: 'one_year', label: '1 Year' },
  { value: 'two_years', label: '2 Years' },
  { value: 'three_years', label: '3 Years' },
  { value: 'four_years', label: '4 Years' },
  { value: 'five_plus_years', label: '5+ Years' },
];

// tazworks
export const TAZWORKS_STATUSES = {
  APP_PENDING: 'x:app_pending',
  APPLICANT_PENDING: 'x:applicant-pending',
  PENDING: 'x:pending',
  APPLICANT_READY: 'x:applicant-ready',
  READY: 'x:ready',
  CANCELED: 'x:canceled',
};

export const STATES_LIST = [
  { value: '', label: '- Choose One -', isDefault: true },
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const STATES_LIST_MAP = buildValueMap(STATES_LIST);

export const VALIDATE_PASSWORD_ERROR_MESSAGE = 'Invalid password.';
export const VALIDATE_EMAIL_NOT_FOUND_MESSAGE = 'This email does not exist.';
export const VALIDATE_LOGIN_GENERAL_ERROR = 'Sorry, something went wrong';
export const VALIDATE_EMAIL_NOT_VALID_MESSAGE = 'Please enter a valid email address.';
export const RETRY_ERROR_MESSAGE = 'Something went wrong. Please try again.';
export const SUPPORT_EMAIL = 'safesupport@rentsafe.lease';
export const RETRY_OR_CONTACT_ERROR_MESSAGE = `Sorry, something went wrong. Please try again or contact ${SUPPORT_EMAIL}.`;
export const VALIDATE_BACKGROUND_FIRST_NAME = 'Please enter your first name.';
export const VALIDATE_BACKGROUND_LAST_NAME = 'Please enter your last name.';
export const VALIDATE_CONSENT = 'Please consent to share information.';
export const VALIDATE_FIELD = 'is a required field.';
export const VALIDATE_FIRST_NAME = 'Please enter a valid first name.';
export const VALIDATE_LAST_NAME = 'Please enter a valid last name.';
export const VALIDATE_EMAIL_ADDRESS = 'Please enter a valid email address.';
export const VALIDATE_MATCHING_EMAIL = 'Email address does not match.';
export const VALIDATE_8_CHARACTERS_PASSWORD = 'At least 8 characters.';
export const VALIDATE_1_NUMBER_PASSWORD = 'At least 1 number.';
export const VALIDATE_1_LETTER_PASSWORD = 'At least 1 letter.';
export const VALIDATE_CONFIRM_PASSWORD = 'Please confirm your chosen password.';
export const VALIDATE_TERMS_AGREEMENT = 'Please agree to Terms and Conditions to continue.';
export const VALIDATE_FCRA_ACKNOWLEDGES = 'Please acknowledge FCRA to continue.';
export const VALIDATE_PROFILE_INFO = 'You must confirm above information is correct to continue.';
export const VALIDATE_ZIP_CODE = 'Please enter a valid zipcode.';
export const VALIDATE_COMPANY_NAME = 'Please enter a valid company name.';
export const VALIDATE_INFO_CONFIRM = 'Please confirm the above information is correct.';
export const VALIDATE_PASSWORD = 'Please enter a password.';
export const VALIDATE_STATUS = 'Status is required.';
export const VALIDATE_DENIAL_REASON = 'A denial reason is required.';
export const VALIDATE_UPDATE_EXPLAINED = 'An explained reason for updates is required.';
export const VALIDATE_DENIAL_EXPLAINED = 'An explained reason for denial is required.';
export const VALIDATE_DATE_FORMAT = 'Please enter a valid date of format mm/dd/yyyy.';
export const VALIDATE_ACCEPTED_REFERENCE_LETTER_TYPE =
  'Please enter all accepted reference letter types.';
export const VALIDATE_ACCEPTED_PROOF_OF_INCOME_TYPE =
  'Please enter all accepted proof of income types.';
export const VALIDATE_DENIED_CRIMINAL = 'Specificity is required'

export const VALIDATE_SSN = 'Please enter a valid SSN of format xxx-xx-xxxx';

export const VALIDATE_PET_TYPE = 'You must select the type of pets allowed at this property.';
export const VALIDATE_ANIMAL_TYPE = 'Please select an animal type.';
export const VALIDATE_BREED = 'Please enter a breed.';
export const VALIDATE_3_CHARACTERS = 'Please enter at least 3 characters.';
export const VALIDATE_PET_WEIGHT = 'Please enter your pets weight in lbs.';
export const VALIDATE_PET_AGE = 'Please enter your pets age.';

export const VALIDATE_DISCLOSURE_DOC_TITLE = 'Please enter a document title.';

export const VALIDATE_ONE_FILE = 'At least 1 file is required.';
export const VALIDATE_NUMBER_OF_MINORS =
  'Please select the number of minors that will be living at this address.';
export const VALIDATE_NUMBER_OF_PETS =
  'Please select how many pets that will be living at this address.';
export const VALIDATE_MOVE_IN_DATE = 'Please enter a valid move-in date.';
export const VALIDATE_LEASE_TERM = 'Please select a lease length.';
export const VALIDATE_DESCRIPTION_LENGTH = 'Your description is too long.';
export const VALIDATE_EMAIL_VERIFICATION = 'Please submit an email address for verification.';
export const VALIDATE_LETTER_OF_RECOMMENDATION = 'Please enter a letter of recommendation.';
export const VALIDATE_INTERNATIONAL_RENTAL_HISTORY =
  'Please enter details on your international rental history.';
export const VALIDATE_EMPLOYMENT_STATUS = 'Please select an employment status.';
export const VALIDATE_RESIDENCY_LENGTH = 'Please enter the length of residency at this property.';
export const VALIDATE_PHONE_NUMBER = 'Please enter a valid phone number.';

export const VALIDATE_PROPERTY_ADDRESS = 'Please enter an address for this property.';
export const VALIDATE_PROPERTY_CITY = 'Please enter a city for this property.';
export const VALIDATE_PROPERTY_STATE = 'Please enter a state for this property.';
export const VALIDATE_PROPERTY_ZIP = 'Please enter a zip code for this property.';
export const VALIDATE_PROPERTY_MOVE_IN_DATE = 'Please enter a move-in date for this property.';

export const VALIDATE_INCOME_SOURCE = 'Please select a source of income.';
export const VALIDATE_INCOME_MONTHLY = 'Please enter a valid monthly income.';
export const VALIDATE_INCOME_SOURCE_OTHER = 'Please enter a valid source of income.';

export const VALIDATE_EMPLOYMENT_EMPLOYER = 'Please enter a valid employer.';
export const VALIDATE_EMPLOYMENT_POSITION = 'Please enter a valid position.';
export const VALIDATE_EMPLOYMENT_LENGTH = 'Please enter a valid length of employment.';
export const VALIDATE_EMPLOYMENT_ADDRESS = 'Please enter a valid employer address.';
export const VALIDATE_EMPLOYMENT_PHONE = 'Please enter a valid employer phone number.';

export const VALIDATE_EMPLOYER_ADDRESS_STREET = 'Please enter a street address.';
export const VALIDATE_EMPLOYER_ADDRESS_CITY = 'Please enter a city name.';
export const VALIDATE_EMPLOYER_ADDRESS_STATE = 'Please select a state.';
export const VALIDATE_EMPLOYER_ADDRESS_ZIP = 'Please enter a valid zip code.';
export const VALIDATE_EMPLOYER_ADDRESS_COUNTRY = 'Please enter a valid country.';

export const CONFIRM_CHANGES_SAVE = 'Your changes have been saved.';

export const NUMBER_OF_UNITS = [
  { value: '', label: '- Choose One -', isDefault: true },
  { value: '0-60', label: '0-50' },
  { value: '50-200', label: '50-200' },
  { value: '200-500', label: '200-500' },
  { value: '500-1000', label: '500-1000' },
  { value: '1000+', label: '1000+' },
  { value: 'N/A', label: 'N/A' },
];

export const NUMBER_OF_LEASED_UNITS = [
  { value: '', label: '- Choose One -', isDefault: true },
  { value: '0-50', label: '0-50' },
  { value: '50-200', label: '50-200' },
  { value: '200-500', label: '200-500' },
  { value: '1000+', label: '1000+' },
];

export const OTHER_INCOME_SOURCE = 'other';
export const ADDITIONAL_SOURCES_OF_INCOME = [
  { value: 'child-support', label: 'Child Support' },
  { value: 'alimony', label: 'Alimony' },
  { value: 'investment', label: 'Investment' },
  { value: 'rental-property', label: 'Rental Property' },
  { value: OTHER_INCOME_SOURCE, label: 'Other' },
];

export const REASONS_APP_DENIED_SHORT_LABEL = [
  { value: 'derogatory_payment_history', label: 'Derogatory Payment History' },
  { value: 'credit_report', label: 'Credit Report' },
  { value: 'background_check', label: 'Background Check' },
  { value: 'income', label: 'Inadequate Income' },
  { value: 'references', label: 'References' },
  { value: 'incomplete', label: 'Incomplete / Incorrect' },
  { value: 'no_longer_available', label: 'Rental Is Unavailable' },
  { value: 'another_app_set', label: 'Another Application Selected' },
  { value: 'criminal', label: 'Criminal accusation or conviction' },
  { value: APP_STATUS_DENIED_OTHER, label: 'Other Reason' },
];

export const REASONS_APP_DENIED = [
  { value: 'derogatory_payment_history', label: 'Past derogatory payment history information' },
  { value: 'credit_report', label: 'Credit report did not meet requirements', disabled: true }, // only here for historical purposes
  { value: 'background_check', label: 'Background check did not meet requirements' },
  { value: 'income', label: 'Income was inadequate for this property' },
  { value: 'references', label: 'Lack of references / poor references' },
  { value: 'incomplete', label: 'Application is incomplete / incorrect' },
  { value: 'no_longer_available', label: 'Rental is no longer available' },
  { value: 'another_app_set', label: 'Another application set was selected' },
  { value: 'criminal', label: 'A pending criminal accusation or criminal conviction for one of the crimes listed in the Code of the District of Columbia § 42-3541.02' },
  { value: APP_STATUS_DENIED_OTHER, label: 'Other' },
];

export const UPLOADED_DOCUMENT_TYPES = {
  DISCLOSURE_DOCUMENTS: 'disclosureDocuments',
  EMPLOYMENT_UPLOADS: 'employmentUploads',
  LETTER_OF_RECOMMENDATION: 'letterOfRecommendation',
  PET_IMAGE: 'petImage',
  PET_VET_RECORD: 'petVetRecord',
  PROPERTY_PHOTO: 'propertyPhoto',
  DENIAL_LETTER: 'denialLetter',
};

export const SHAREABLE_BUNDLE_OPTIONS = [
  { label: 'Credit + Criminal', value: 2 },
  { label: 'Credit + Criminal + Eviction', value: 3 },
];

export const VALID_NOMADIC_STATES = ['DC','MD','VA'];

export const USA = 'United States of America';
export const COUNTRY_LIST = [
  { value: USA, label: 'United States of America' },
  { value: 'Canada', label: 'Canada' },
  { value: 'Afganistan', label: 'Afghanistan' },
  { value: 'Albania', label: 'Albania' },
  { value: 'Algeria', label: 'Algeria' },
  { value: 'American Samoa', label: 'American Samoa' },
  { value: 'Andorra', label: 'Andorra' },
  { value: 'Angola', label: 'Angola' },
  { value: 'Anguilla', label: 'Anguilla' },
  { value: 'Antigua & Barbuda', label: 'Antigua & Barbuda' },
  { value: 'Argentina', label: 'Argentina' },
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Aruba', label: 'Aruba' },
  { value: 'Australia', label: 'Australia' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Azerbaijan', label: 'Azerbaijan' },
  { value: 'Bahamas', label: 'Bahamas' },
  { value: 'Bahrain', label: 'Bahrain' },
  { value: 'Bangladesh', label: 'Bangladesh' },
  { value: 'Barbados', label: 'Barbados' },
  { value: 'Belarus', label: 'Belarus' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Belize', label: 'Belize' },
  { value: 'Benin', label: 'Benin' },
  { value: 'Bermuda', label: 'Bermuda' },
  { value: 'Bhutan', label: 'Bhutan' },
  { value: 'Bolivia', label: 'Bolivia' },
  { value: 'Bonaire', label: 'Bonaire' },
  { value: 'Bosnia & Herzegovina', label: 'Bosnia & Herzegovina' },
  { value: 'Botswana', label: 'Botswana' },
  { value: 'Brazil', label: 'Brazil' },
  { value: 'British Indian Ocean Ter', label: 'British Indian Ocean Ter' },
  { value: 'Brunei', label: 'Brunei' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Burkina Faso', label: 'Burkina Faso' },
  { value: 'Burundi', label: 'Burundi' },
  { value: 'Cambodia', label: 'Cambodia' },
  { value: 'Cameroon', label: 'Cameroon' },
  { value: 'Canary Islands', label: 'Canary Islands' },
  { value: 'Cape Verde', label: 'Cape Verde' },
  { value: 'Cayman Islands', label: 'Cayman Islands' },
  { value: 'Central African Republic', label: 'Central African Republic' },
  { value: 'Chad', label: 'Chad' },
  { value: 'Channel Islands', label: 'Channel Islands' },
  { value: 'Chile', label: 'Chile' },
  { value: 'China', label: 'China' },
  { value: 'Christmas Island', label: 'Christmas Island' },
  { value: 'Cocos Island', label: 'Cocos Island' },
  { value: 'Colombia', label: 'Colombia' },
  { value: 'Comoros', label: 'Comoros' },
  { value: 'Congo', label: 'Congo' },
  { value: 'Cook Islands', label: 'Cook Islands' },
  { value: 'Costa Rica', label: 'Costa Rica' },
  { value: 'Cote DIvoire', label: 'Cote DIvoire' },
  { value: 'Croatia', label: 'Croatia' },
  { value: 'Cuba', label: 'Cuba' },
  { value: 'Curacao', label: 'Curacao' },
  { value: 'Cyprus', label: 'Cyprus' },
  { value: 'Czech Republic', label: 'Czech Republic' },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Djibouti', label: 'Djibouti' },
  { value: 'Dominica', label: 'Dominica' },
  { value: 'Dominican Republic', label: 'Dominican Republic' },
  { value: 'East Timor', label: 'East Timor' },
  { value: 'Ecuador', label: 'Ecuador' },
  { value: 'Egypt', label: 'Egypt' },
  { value: 'El Salvador', label: 'El Salvador' },
  { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
  { value: 'Eritrea', label: 'Eritrea' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Ethiopia', label: 'Ethiopia' },
  { value: 'Falkland Islands', label: 'Falkland Islands' },
  { value: 'Faroe Islands', label: 'Faroe Islands' },
  { value: 'Fiji', label: 'Fiji' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { value: 'French Guiana', label: 'French Guiana' },
  { value: 'French Polynesia', label: 'French Polynesia' },
  { value: 'French Southern Ter', label: 'French Southern Ter' },
  { value: 'Gabon', label: 'Gabon' },
  { value: 'Gambia', label: 'Gambia' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Ghana', label: 'Ghana' },
  { value: 'Gibraltar', label: 'Gibraltar' },
  { value: 'Great Britain', label: 'Great Britain' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Greenland', label: 'Greenland' },
  { value: 'Grenada', label: 'Grenada' },
  { value: 'Guadeloupe', label: 'Guadeloupe' },
  { value: 'Guam', label: 'Guam' },
  { value: 'Guatemala', label: 'Guatemala' },
  { value: 'Guinea', label: 'Guinea' },
  { value: 'Guyana', label: 'Guyana' },
  { value: 'Haiti', label: 'Haiti' },
  { value: 'Hawaii', label: 'Hawaii' },
  { value: 'Honduras', label: 'Honduras' },
  { value: 'Hong Kong', label: 'Hong Kong' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'Indonesia', label: 'Indonesia' },
  { value: 'India', label: 'India' },
  { value: 'Iran', label: 'Iran' },
  { value: 'Iraq', label: 'Iraq' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Isle of Man', label: 'Isle of Man' },
  { value: 'Israel', label: 'Israel' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Jordan', label: 'Jordan' },
  { value: 'Kazakhstan', label: 'Kazakhstan' },
  { value: 'Kenya', label: 'Kenya' },
  { value: 'Kiribati', label: 'Kiribati' },
  { value: 'Korea North', label: 'Korea North' },
  { value: 'Korea South', label: 'Korea South' },
  { value: 'Kuwait', label: 'Kuwait' },
  { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  { value: 'Laos', label: 'Laos' },
  { value: 'Latvia', label: 'Latvia' },
  { value: 'Lebanon', label: 'Lebanon' },
  { value: 'Lesotho', label: 'Lesotho' },
  { value: 'Liberia', label: 'Liberia' },
  { value: 'Libya', label: 'Libya' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lithuania', label: 'Lithuania' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Macau', label: 'Macau' },
  { value: 'Macedonia', label: 'Macedonia' },
  { value: 'Madagascar', label: 'Madagascar' },
  { value: 'Malaysia', label: 'Malaysia' },
  { value: 'Malawi', label: 'Malawi' },
  { value: 'Maldives', label: 'Maldives' },
  { value: 'Mali', label: 'Mali' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Marshall Islands', label: 'Marshall Islands' },
  { value: 'Martinique', label: 'Martinique' },
  { value: 'Mauritania', label: 'Mauritania' },
  { value: 'Mauritius', label: 'Mauritius' },
  { value: 'Mayotte', label: 'Mayotte' },
  { value: 'Mexico', label: 'Mexico' },
  { value: 'Midway Islands', label: 'Midway Islands' },
  { value: 'Moldova', label: 'Moldova' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Mongolia', label: 'Mongolia' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'Morocco', label: 'Morocco' },
  { value: 'Mozambique', label: 'Mozambique' },
  { value: 'Myanmar', label: 'Myanmar' },
  { value: 'Nambia', label: 'Nambia' },
  { value: 'Nauru', label: 'Nauru' },
  { value: 'Nepal', label: 'Nepal' },
  { value: 'Netherland Antilles', label: 'Netherland Antilles' },
  { value: 'Netherlands', label: 'Netherlands (Holland, Europe)' },
  { value: 'Nevis', label: 'Nevis' },
  { value: 'New Caledonia', label: 'New Caledonia' },
  { value: 'New Zealand', label: 'New Zealand' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Niger', label: 'Niger' },
  { value: 'Nigeria', label: 'Nigeria' },
  { value: 'Niue', label: 'Niue' },
  { value: 'Norfolk Island', label: 'Norfolk Island' },
  { value: 'Norway', label: 'Norway' },
  { value: 'Oman', label: 'Oman' },
  { value: 'Pakistan', label: 'Pakistan' },
  { value: 'Palau Island', label: 'Palau Island' },
  { value: 'Palestine', label: 'Palestine' },
  { value: 'Panama', label: 'Panama' },
  { value: 'Papua New Guinea', label: 'Papua New Guinea' },
  { value: 'Paraguay', label: 'Paraguay' },
  { value: 'Peru', label: 'Peru' },
  { value: 'Phillipines', label: 'Philippines' },
  { value: 'Pitcairn Island', label: 'Pitcairn Island' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Puerto Rico', label: 'Puerto Rico' },
  { value: 'Qatar', label: 'Qatar' },
  { value: 'Republic of Montenegro', label: 'Republic of Montenegro' },
  { value: 'Republic of Serbia', label: 'Republic of Serbia' },
  { value: 'Reunion', label: 'Reunion' },
  { value: 'Romania', label: 'Romania' },
  { value: 'Russia', label: 'Russia' },
  { value: 'Rwanda', label: 'Rwanda' },
  { value: 'St Barthelemy', label: 'St Barthelemy' },
  { value: 'St Eustatius', label: 'St Eustatius' },
  { value: 'St Helena', label: 'St Helena' },
  { value: 'St Kitts-Nevis', label: 'St Kitts-Nevis' },
  { value: 'St Lucia', label: 'St Lucia' },
  { value: 'St Maarten', label: 'St Maarten' },
  { value: 'St Pierre & Miquelon', label: 'St Pierre & Miquelon' },
  { value: 'St Vincent & Grenadines', label: 'St Vincent & Grenadines' },
  { value: 'Saipan', label: 'Saipan' },
  { value: 'Samoa', label: 'Samoa' },
  { value: 'Samoa American', label: 'Samoa American' },
  { value: 'San Marino', label: 'San Marino' },
  { value: 'Sao Tome & Principe', label: 'Sao Tome & Principe' },
  { value: 'Saudi Arabia', label: 'Saudi Arabia' },
  { value: 'Senegal', label: 'Senegal' },
  { value: 'Seychelles', label: 'Seychelles' },
  { value: 'Sierra Leone', label: 'Sierra Leone' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'Slovakia', label: 'Slovakia' },
  { value: 'Slovenia', label: 'Slovenia' },
  { value: 'Solomon Islands', label: 'Solomon Islands' },
  { value: 'Somalia', label: 'Somalia' },
  { value: 'South Africa', label: 'South Africa' },
  { value: 'Spain', label: 'Spain' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
  { value: 'Sudan', label: 'Sudan' },
  { value: 'Suriname', label: 'Suriname' },
  { value: 'Swaziland', label: 'Swaziland' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Syria', label: 'Syria' },
  { value: 'Tahiti', label: 'Tahiti' },
  { value: 'Taiwan', label: 'Taiwan' },
  { value: 'Tajikistan', label: 'Tajikistan' },
  { value: 'Tanzania', label: 'Tanzania' },
  { value: 'Thailand', label: 'Thailand' },
  { value: 'Togo', label: 'Togo' },
  { value: 'Tokelau', label: 'Tokelau' },
  { value: 'Tonga', label: 'Tonga' },
  { value: 'Trinidad & Tobago', label: 'Trinidad & Tobago' },
  { value: 'Tunisia', label: 'Tunisia' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'Turkmenistan', label: 'Turkmenistan' },
  { value: 'Turks & Caicos Is', label: 'Turks & Caicos Is' },
  { value: 'Tuvalu', label: 'Tuvalu' },
  { value: 'Uganda', label: 'Uganda' },
  { value: 'United Kingdom', label: 'United Kingdom' },
  { value: 'Ukraine', label: 'Ukraine' },
  { value: 'United Arab Erimates', label: 'United Arab Emirates' },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Uzbekistan', label: 'Uzbekistan' },
  { value: 'Vanuatu', label: 'Vanuatu' },
  { value: 'Vatican City State', label: 'Vatican City State' },
  { value: 'Venezuela', label: 'Venezuela' },
  { value: 'Vietnam', label: 'Vietnam' },
  { value: 'Virgin Islands (Brit)', label: 'Virgin Islands (Brit)' },
  { value: 'Virgin Islands (USA)', label: 'Virgin Islands (USA)' },
  { value: 'Wake Island', label: 'Wake Island' },
  { value: 'Wallis & Futana Is', label: 'Wallis & Futana Is' },
  { value: 'Yemen', label: 'Yemen' },
  { value: 'Zaire', label: 'Zaire' },
  { value: 'Zambia', label: 'Zambia' },
  { value: 'Zimbabwe', label: 'Zimbabwe' },
];
