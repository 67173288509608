import React from "react";
import classnames from 'classnames';
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";

const TransUnionReportsTabs = ({ reports, activeTab, setActiveTab }) => {
  if (!reports) {
    return null;
  }
  return (
    <>
      <Nav tabs>
        {reports?.map((r, i) => (
          <NavItem key={r.type}>
            <NavLink
              className={classnames({ active: activeTab === i })}
              onClick={() => { setActiveTab(i) }}
            >
              { r.type === 'Eviction' ? 'Eviction Related Proceedings' : r.type }
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {reports?.map((r, i) => (
          <TabPane key={r.type} tabId={i}>
            <div dangerouslySetInnerHTML={{ __html: r.html }} />
          </TabPane>
        ))}
      </TabContent>
    </>
  )
};

export default TransUnionReportsTabs;
