import React from 'react';
import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';
import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { H1 } from 'components/Typography';

const PageHeadingIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

const StyledH2 = styled(H1)`
  margin: 1rem 0 2rem;
  font-size: 1.5rem;
  text-align: left;

  ${ifProp(
    { type: 'dashboard' },
    css`
      margin: 0;
    `
  )}
`;

function PageHeading({ icon, title = '', type = '', iconAlt }) {
  return (
    <StyledH2 type={type}>
      {icon ? <PageHeadingIcon icon={icon} alt={iconAlt} role='presentation' /> : null}
      {title}
    </StyledH2>
  );
}

PageHeading.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
  iconAlt: PropTypes.string,
};

export default PageHeading;
