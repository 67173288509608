import styled, { css } from 'styled-components/macro';
import { ifNotProp, theme } from 'styled-tools';
import { up } from 'styled-breakpoints';
// For pages in and out of white backgrounds, this wrapper
// will ensure your content doesn't overlap the footer.
export const PropertyPageWrapper = styled.div`
  padding-bottom: 4rem;
  color: ${theme('colors.primary')};
`;

const ContentWrapper = styled.div`
  background-color: ${theme('colors.white')};
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  color: ${theme('colors.primary')};

  ${up('lgTablet')} {
    padding: 1.5rem;
  }

  ${ifNotProp(
    'fullwidth',
    css`
      max-width: 60rem;
      margin: 5rem auto;
    `,
    css`
      margin: 0 auto;
    `
  )}
`;

export default ContentWrapper;
