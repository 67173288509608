/** global: localStorage */
import jwtDecode from 'jwt-decode';

const USER_AUTH_TOKEN = 'rentsafe_auth_token';

const IMPERSONATING_KEY = 'impersonating_user';

export const getToken = () => localStorage.getItem(USER_AUTH_TOKEN);

export const setToken = token => localStorage.setItem(USER_AUTH_TOKEN, token);

export const removeToken = () => localStorage.removeItem(USER_AUTH_TOKEN);

export const getUserData = () => isAuthenticated() && jwtDecode(getToken());

export const isAuthenticated = () => !!getToken();

export const clearSessionStorage = () => {
  removeToken();
};

export const isImpersonating = () => !!(localStorage.getItem(IMPERSONATING_KEY));

export const setImpersonating = impersonating => localStorage.setItem(IMPERSONATING_KEY, impersonating);

export const removeImpersonatingKey = () => localStorage.removeItem(IMPERSONATING_KEY);
