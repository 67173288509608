import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { up } from 'styled-breakpoints';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { withContext } from 'utils/hocs';
import Routes from './routes';
import { FlashProvider } from 'contexts/flashContext';
import Footer from 'components/Footer';
import Nav from './components/Nav';
import { getUserData } from 'utils/auth';
import GoogleAnalyticsListener from 'components/GoogleAnalyticsListener';
import './styles/index.scss';
import ErrorBoundary from 'components/ErrorBoundary';
import { useLocation } from 'react-router-dom';
import { isAdmin, isPropertyManager, ROLE_PROPERTY_MANAGER } from 'utils/roles';

const SiteWrapper = styled.div`
  background: ${theme('colors.secondary.regular')};
  padding-top: 5rem;
  min-height: calc(100% - 5.938rem);

  ${up('lgTablet')} {
    min-height: calc(100% - 3.313rem);
  }
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function App() {
  const userData = getUserData();
  const location = useLocation();

  const isLandlord = isAdmin(userData) || isPropertyManager(userData) || location.pathname === '/pm-portal';

  return (
    <GoogleAnalyticsListener
      trackingId={process.env.REACT_APP_GOOGLE_ANALYTICS_ID}
      userId={userData?.id}
    >
      <Elements stripe={stripePromise}>
        <SiteWrapper>
          <Nav />
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </SiteWrapper>
        <Footer propertyManager={isLandlord} />
      </Elements>
    </GoogleAnalyticsListener>
  );
}

export default withContext(App, FlashProvider);
