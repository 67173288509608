import React, { useEffect } from "react";
import { StaticPageWrapper } from "components/Layout";
import { Col, Container, Row } from "reactstrap";
import PageHeading from "components/PageHeading";
import { useParams } from "react-router-dom";
import useShareableReports from "hooks/useShareableReports";
import Loader from "components/Loader";
import TransUnionReportsTabs from "components/TransUnionReportsTabs";
import ContentWrapper from "components/ContentWrapper";

function ShareableReports() {
  const { id } = useParams();

  const {
    startPolling,
    loading,
    reports: tuReports,
    activeTab,
    setActiveTab,
  } = useShareableReports(id);

  useEffect(() => {
    startPolling();
  }, [startPolling]);

  return (
    <StaticPageWrapper>
      <Container>
        <PageHeading title='Reports' />

        <Row>
          <Col>
            {loading && (
              <Loader />
            )}
            {!loading && (
              <ContentWrapper fullwidth>
                <TransUnionReportsTabs
                  reports={tuReports}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </ContentWrapper>
            )}
          </Col>
        </Row>
      </Container>
    </StaticPageWrapper>
  )
}

export default ShareableReports;
