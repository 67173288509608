import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { AuthProvider } from 'contexts/authContext';
import App from './App';
import theme from './theme';
import GlobalStyle from './GlobalStyle';
import { isAuthenticated, getToken } from 'utils/auth';
import { createUploadLink } from 'apollo-upload-client';
import ErrorBoundary from 'components/ErrorBoundary';
import { CookiesProvider } from "react-cookie";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL + '/graphql',
  headers: {
    authorization: isAuthenticated() ? `Bearer ${getToken()}` : '',
  },
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: isAuthenticated() ? `Bearer ${getToken()}` : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const ReactApp = () => (
  <ThemeProvider theme={theme}>
    <ErrorBoundary>
      <AuthProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <GlobalStyle />
            <App />
          </BrowserRouter>
        </ApolloProvider>
      </AuthProvider>
    </ErrorBoundary>
  </ThemeProvider>
);

ReactDOM.render(<CookiesProvider><ReactApp /></CookiesProvider>, document.getElementById('root'));
